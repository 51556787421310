@import "config";

@include non-critical() {
	/*! ************************************************************************
	 * iOS webkit bug begin
	 * On iPhone Plus landscape with tab bar enabled
	 * https://bugs.webkit.org/show_bug.cgi?id=153056
	 **************************************************************************/
	.cookie-hint {
		position: static !important;
	}
	.header {
		position: relative !important;
	}
	.main--wrapper {
		padding-top: 0 !important;
	}
	.mainmenu {
		position: absolute !important;
		@include from(desktop) {
			position: relative !important;
		}
	}
	/*! ************************************************************************
	 * iOS webkit bug end
	 **************************************************************************/
}
